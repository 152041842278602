// Product Modal
.productmodal {
    position: relative;
    .MuiBackdrop-root {
        backdrop-filter: blur(3.4px);
        -webkit-backdrop-filter: blur(3.4px);
    }
    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: auto;
        cursor: pointer;
				z-index: 30;
				@include tablet {
					background-color: rgba(74, 74, 74, 0.12);
					border-radius: 50px;
				}
    }
    .MuiPaper-root {
        max-width: 1280px;
        width: 100%;
        margin: 100px;
        max-height: calc(100% - 100px);
				@include tablet {
					margin: 50px;
				}
				@include tablet {
					margin: 25px;
				}
        .MuiDialogContent-root {
            padding: 0;
            display: flex;
            @include tablet {
                flex-direction: column;
            }
            .slick-slider {
                max-width: 40%;
                width: 100%;
                @include tablet {
                    max-width: none;
                    height: 400px;
                }
                @include phone {
                    max-width: none;
                    height: 300px;
                }
                .slick-list {
                    height: 100%;
                    .slick-track {
                        height: 100%;
                        .slick-slide {
                            div {
                                height: 100%;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                        }
                    }
                }
                .slick-dots {
                    bottom: 0;
                }
            }
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        margin: 40px;
        gap: 16px;
				@include phone {
					margin: 15px;
				}
    }
    &__title {
        font-family: $kansas;
        font-size: 38px;
        font-weight: 600;
        margin: 0;
				@include phone {
					font-size: 26px;
				}
    }
    &__desc {
        font-family: $europa;
        font-size: 18px;
        color: $grey;
        margin: 0;
    }
    &__tags {
        background: rgba(74, 74, 74, 0.12);
        border-radius: 66px;
        padding: 8px 16px;
        font-family: $europa;
        font-size: 13px;
        font-weight: 700;
        margin: 0;
        &--container {
            display: flex;
            align-items: center;
            gap: 15px;
        }
    }
    &__cta {
        margin: 16px 0;
        width: 316px;
				@include phone {
					width: unset;
				}
    }
    &__tabs {
        border-bottom: solid 1px #00000033;
        .MuiTabs-flexContainer {
            gap: 20px;
        }
    }
    &__tab {
        font-family: $europa !important;
        font-size: 18px !important;
        text-transform: none !important;
        color: #00000033 !important;
        padding: 0 !important;
        min-width: min-content !important;
        .MuiTab-wrapper {
            align-items: flex-start !important;
        }
    }
    &__tab.Mui-selected {
        color: #000 !important;
        font-weight: 700 !important;
    }
    .MuiTabs-indicator {
        opacity: 0 !important;
    }
    .MuiBox-root {
        padding: 0 !important;
        p {
            font-family: $europa !important;
            font-size: 16px !important;
            color: $grey90;
            line-height: 150% !important;
        }
    }
    .button {
        border-radius: 80px;
        padding: 1.25rem 1.75rem;
        font-family: $europa;
        font-weight: 700;
        font-size: 15px;
        &__cta {
            border: unset;
            color: white;
            &--orange {
                background: $orange-linear;
                &:hover {
                    position: relative;
                    // padding: 1.25rem 1.75rem;
                    background: linear-gradient(90deg, #e7762d 0%, #f99f24 164.57%);
                    &::after {
                        position: absolute;
                        top: -4px;
                        left: -4px;
                        content: "";
                        width: 100%;
                        height: 100%;
                        padding: 4px;
                        background-color: rgba(231, 118, 45, 0.24);
                        box-shadow: 0px 4px 16px rgba(253, 127, 43, 0.32);
                        border-radius: 80px;
                    }
                }
            }
            &--green {
                background: $green-linear;
                &:hover {
                    position: relative;
                    // padding: calc(1.25rem - 4px) calc(1.75rem - 4px);
                    background: linear-gradient(90deg, #52923c 0%, #9fcb26 152.14%);
                    &::after {
                        position: absolute;
                        top: -4px;
                        left: -4px;
                        content: "";
                        width: 100%;
                        height: 100%;
                        padding: 4px;
                        background-color: rgba(130, 163, 51, 0.24);
                        box-shadow: 0px 4px 16px rgba(82, 146, 60, 0.32);
                        border-radius: 80px;
                    }
                }
            }
        }
        &__regular {
            border: 2px solid rgba(74, 74, 74, 0.08);
            background-color: transparent;
            align-items: center;
            &--socials {
                position: relative;
                height: 50px;
                width: 50px;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        &__play {
            background-color: white;
            border: 0;
            box-shadow: 0px 4px 16px rgba(35, 35, 35, 0.16);
            width: 56px;
            height: 56px;
            position: relative;
            svg {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 50%;
                left: 52%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
