.nav {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 50;
  &__container {
    max-width: 1280px;
    margin: auto;
    &--content {
      display: flex;
      justify-content: space-between;
      padding: 2rem 1.5rem;
      @include tablet {
        padding: 1rem 1.5rem;
        position: relative;
      }
    }
  }
  &__left {
    display: flex;
    align-items: center;
    &--logo {
      margin-right: 3.5rem;
    }
    &--links {
      display: flex;
      gap: 2rem;
      .link {
        font-family: $europa;
        font-size: 15px;
        font-weight: 700;
        @include tablet {
          font-size: 22px;
        }
      }
      @include tablet {
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: 50;
        right: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    &--links.open {
      background-color: white;
      left: 0;
      padding-top: 3.5rem;
    }
    &--links  .active {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        background-color: $green;
        left: -2px;
        bottom: -49px;
        border: 2px solid $green;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 1px;
        width: 100%;
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
    gap: 2.25rem;
    &--buttons {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;
    }
    @include tablet {
      gap: 1.5rem;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__buttons {
    &--list {
      display: flex;
      gap: 1rem;
    }
  }
  &__bar {
    position: relative;
    width: 60%;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-top: 1px solid #4A4A4A16;
      width: 100%;
      height: 1px;
    }
  }
}