.affiliate__top {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 152px);
}
.affiliate__background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.affiliate__container {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    background-color: white;
    border-radius: 30px;
    left: 20%;
    padding: 40px;
    max-width: 750px;
    .MuiFormControl-root {
        margin-top: 20px;
    }
    legend {
        font-family: $Roboto;
    }
    &.faim {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $mcf-blue;
        }
    }
    &.Matcha {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $gm-green;
        }
    }
    &.Aktivation {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $akt-light-blue;
        }
    }
    &.Choko {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $gc-gold;
        }
    }
    &.Krisp {
      .MuiCheckbox-colorSecondary.Mui-checked {
          color: $gk-red;
      }
  }
}
.affiliate__title {
    font-size: 1.5rem;
    font-family: $Roboto;
    font-weight: bold;
    &.faim {
        color: $mcf-blue;
    }
    &.Matcha {
        color: $gm-green;
    }
    &.Aktivation {
        color: $akt-light-blue;
    }
    &.Choko {
        color: $gc-brown;
    }
    &.Krisp {
      color: $gk-dark-beige;
  }
}
.affiliate__cta {
    margin-top: 25px;
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    font-family: $Roboto;
    font-weight: bold;
    color: white;
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
      background-color: $gk-red;
  }
}
@media screen and (max-width: 1200px) {
    .affiliate__container {
        left: inherit;
        margin: 5px;
    }
    .affiliate__top {
        justify-content: flex-end;
    }
}