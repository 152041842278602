.akt {
    font-family: $Montserrat;
}
.akt__sub__warning {
    p {
        font-weight: bold;
    }
}
.akt__sub__warning__title {
    text-align: center;
    color: $akt-dark-teal;
    font-weight: 900;
}
.akt__sub__warning__item {
    text-align: center;
    color: $akt-light-blue;
}