.akt {
    // HERO
    .hero {
        padding-top: 56.25%;
        .jwplayer {
            position: absolute !important;
            top: 152px !important;
        }
        .jw-controlbar {
            display: none;
        }
    }
    .hero__logo {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
    .hero__cta {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 75%;
        left: 50%;
        font-weight: 900;
        color: white;
        font-size: 1.5rem;
        padding: 10px 40px;
        background-color: $akt-light-blue;
        border: none;
        border-radius: 25px;
    }
    @media screen and (max-width: 1410px) {
        .hero {
            .jwplayer {
                top: 55px !important;
            }
        }
    }
    @media screen and (max-width: 1250px) {
        .hero__logo {
            top: 40%;
        }
        .hero__cta {
            top: 65%;
            font-size: 1rem;
        }
    }
    @media screen and (max-width: 1150px) {
        .hero__logo {
            display: none;
        }
        .hero__cta {
            top: 50%;
        }
    }
    @media screen and (max-width: 800px) {
        .hero__cta {
            top: 30%;
            max-width: 90%;
        }
    }
    // SUBHERO
    .subhero__container {
        width: 75%;
        margin: auto;
    }
    .subhero__left, .subhero__right {
        img {
            max-width: 100%;
            margin-top: 30px;
        }
    }
    .subhero__title {
        font-weight: 900;
        color: $akt-dark-teal;
        font-size: 3rem;
    }
    .subhero__text {
        font-weight: 700;
        color: #333;
        font-size: 1.375rem;
    }
    .subhero__cta {
        font-weight: 900;
        color: $akt-dark-teal;
        font-size: 1.375rem;
        padding: 10px 20px;
        border: 2px solid $akt-light-blue;
        background-color: white;
        border-radius: 25px;
    }
    // COMPATIBLE
    .compatible__left {
        img {
            display: block;
            height: fit-content;
            max-width: 100%;
            object-fit: cover;
        }
    }
    .compatible__right {
        background-color: #F2F2F2;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        img {
            max-width: 90%;
            margin: 10px 5px;
            padding: 5px;
        }
        span {
            font-weight: 700;
            font-size: 1.375rem;
            color: $akt-dark-teal;
            padding: 5px;
        }
    }
    .compatible__title {
        font-weight: 900;
        color: $akt-light-blue;
        font-size: 2.625rem;
        padding: 5px;
    }
    // USE CASE
    .use {
        max-height: fit-content;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E2E2E2 100%), #FFFFFF;
    }
    .use__right {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .use__right__container {
        padding: 40px;
        width: 80%;
    }
    .use__title {
        padding-top: 60px;
        margin: 0;
        color: $akt-dark-teal;
        font-size: 2.5rem;
        font-weight: 900;
        text-align: center;
    }
    .use__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 40px 20px;
        img {
            max-width: 100%;
            margin-bottom: 25px;
        }
        span {
            font-size: 1.25rem;
            font-weight: 900;
            color: #333;
            text-align: center;
        }
    }
    .use__text {
        font-size: 1.5rem;
        font-weight: 900;
        color: #333;
        text-align: center;
        max-width: 80%;
        margin: auto;
    }
    .use__cta {
        font-weight: 900;
        color: white;
        font-size: 1.375rem;
        padding: 10px 20px;
        background-color: $akt-light-blue;
        border: none;
        border-radius: 25px;
        margin: 60px auto;
        display: block;
    }
    @media screen and (max-width: 599px) {
        .use__items__mobile__center {
            display: flex; 
            justify-content: center;
        }
    }
    // CONNECT
    .connect {
        background-color: black;
    }
    .connect__container {
        width: 75%;
        margin: auto;
        padding: 20px;
    }
    .connect__title {
        margin: 0;
        color: white;
        font-weight: 900;
        font-size: 2rem;
        display: inline-block;
    }
    .connect__cta {
        font-weight: 900;
        color: white;
        font-size: 1.5rem;
        padding: 10px 40px;
        background-color: $akt-light-blue;
        border: none;
        border-radius: 25px;
        float: right;
    }
    @media screen and (max-width: 980px) {
        .connect__cta {
            display: block;
            float: inherit;
        }
    }
    // WHAT
    .what {
        img {
            max-width: 100%;
            display: block;
        }
    }
    .what__container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        flex-direction: column;
        padding: 40px;
    }
    .what__1 {
        font-weight: 900;
        color: $akt-light-blue;
        font-size: 4rem;
    }
    .what__2 {
        font-weight: 900;
        color: $akt-dark-teal;
        font-size: 3rem;
        margin-top: -1rem;
        margin-bottom: 40px;
    }
    .what__points {
        font-weight: 600;
        color: black;
        font-size: 1.5rem;
        margin: 8px 0px;
    }
    .what__cta {
        font-weight: 900;
        color: white;
        font-size: 1.5rem;
        padding: 10px 40px;
        background-color: $akt-dark-teal;
        border: none;
        border-radius: 25px;
        margin-top: 40px;
        margin-bottom: 20px;
    }
    // SOCIALS
    .socials {
        background: url('../../../assets/shared/socials.png') no-repeat center left;
        background-color: #333;
        background-size: contain;
    }
    .socials__container {
        width: 80%;
        margin: auto;
    }
    .socials__title {
        color: white;
        font-size: 2.625rem;
        font-weight: 900;
        padding: 50px 0;
        margin: 0;
    }
    .socials__icons {
        float: right;
        img {
            margin: 0px 10px;
        }
        a {
            display: inline;
        }
    }
    @media screen and (max-width: 1279px) { 
        .socials {
            background-size: cover;
        }
        .socials__icons {
            display: block;
            float: unset;
        }
    }
    // COACH
    .coach {
        background-color: #F6F6F6;
        padding: 60px;
        // .slick-slide {
        //     margin-left: 10px;
        //     margin-right: 10px;
        // }
        .slick-prev:before, .slick-next:before {
            color: $akt-light-blue;
        }
    }
    .coach__title {
        font-weight: 900;
        font-size: 3rem;
        color: $akt-dark-teal;
        text-align: center;
        margin: 0;
        padding-bottom: 60px;
    }
    .coach__slider {
        margin-bottom: 60px;
    }
    .coach__item {
        background-color: white;
        border-radius: 25px;
        img {
            margin: auto;
            max-width: 100%;
        }
        h5 {
            font-weight: 700;
            font-size: 1.5rem;
            color: $akt-dark-teal;
            text-align: center;
        }
    }
    .coach__cta {
        border-radius: $gm-radius;
        font-size: 1.25rem;
        font-weight: 700;
        padding: 10px 20px;
        background-color: white;
        border: 2px solid $akt-light-blue;
        color: $akt-dark-teal;
        margin: auto;
        display: block;
    }
    @media screen and (max-width: 599px) {
        .coach {
            padding: 60px 10px;
        }
    }
    // PROGRAMMES
    .progs {
        background-color: white;
        img {
            width: 100%;
            display: block;
            cursor: pointer;
        }
    }
    .progs__title {
        font-weight: 900;
        font-size: 3rem;
        color: $akt-dark-teal;
        text-align: center;
    }
    .progs__feat__mobile {
        display: none;
    }
    .progs__teaser__title {
        font-size: 1.5rem;
        font-weight: 600;
        display: inline-block;
    }
    .progs__teaser__description {
        font-size: 1.25rem;
        width: 80%;
    }
    .progs__teaser__duration {
        margin-left: 10px;
        background-color: $akt-light-blue;
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        padding: 8px 16px;
        float: right;
    }
    @media screen and (max-width: 599px) {
        .progs__feat__mobile {
            display: block;
        }
        .progs__feat__desktop {
            display: none;
        }
    }
    // PRODUCTS
    .products {
        background-color: white;
        background: url('../../../assets/akt/products-bg.png') no-repeat center center;
        background-size: cover;
        position: relative;
    }
    .promo__desktop {
        img {
            max-width: 100%;
        }
    }
    .promo__mobile {
        display: none;
    }
    @media screen and (max-width: 599px) {
        .promo__mobile {
            display: block;
            img {
                max-width: 100%;
            }
        }
        .promo__desktop {
            display: none;
        }
    }
    .products__container {
        width: 70%;
        margin: auto;
        padding-bottom: 100px;
    }
    .products__title {
        padding: 60px;
        margin: 0;
        color: $akt-dark-teal;
        font-size: 2.5rem;
        font-weight: 900;
        text-align: center;
    }
    .products__jimmy {
        position: absolute;
        bottom: 0;
        left: 20px;
        height: 70%;
    }
    @media screen and (max-width: 1279px) { 
        .products__jimmy {
            display: none;
        }
    }

    .products__grid {
        justify-content: center;
    }
    .products__item {
        border-radius: $mcf-radius;
        &:hover {
            transform: scale(1.05);
        }
    }
    .products__top {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        &.akt-dark-teal {
            background-color: $akt-dark-teal;
        }
        &.akt-light-blue {
            background-color: $akt-light-blue;
        }
        &.akt-light-red {
            background-color: $akt-light-red;
        }
    }
    .products__top__text {
        color: white;
        font-weight: 500;
        font-size: 1.25rem;
        text-align: center;
        display: block;
        padding: 10px;
    }
    .products__top__text__disclaimer {
        display: block;
        font-size: 0.875rem;
        font-weight: 400;
        opacity: 0.8;
    }
    .products__name {
        background-color: #f2f2f2;
        span {
            color: black;
            font-weight: 800;
            font-size: 1.25rem;
            display: block;
            text-align: center;
            padding: 15px;
        }
    }
    .products__promo {
        color: white;
        padding: 10px;
        &.akt-dark-teal {
            background-color: $akt-dark-teal;
        }
        &.akt-light-blue {
            background-color: $akt-light-blue;
        }
        &.akt-light-red {
            background-color: $akt-light-red;
        }
    }
    .products__promo__strike {
        display: block;
        text-align: center;
        position: relative;
        font-size: 1.25rem;
    }
    .strike-red {
        &::after {
            position: absolute;
            content: "";
            left: 15%;
            top: 50%;
            right: 15%;
            border-top: 2px solid;
            border-color: red;
            
            -webkit-transform:rotate(-5deg);
            -moz-transform:rotate(-5deg);
            -ms-transform:rotate(-5deg);
            -o-transform:rotate(-5deg);
            transform:rotate(-5deg);
        }
    }
    .strike-black {
        &::after {
            position: absolute;
            content: "";
            left: 15%;
            top: 50%;
            right: 15%;
            border-top: 2px solid;
            border-color: black;
            
            -webkit-transform:rotate(-5deg);
            -moz-transform:rotate(-5deg);
            -ms-transform:rotate(-5deg);
            -o-transform:rotate(-5deg);
            transform:rotate(-5deg);
        }
    }
    .products__promo__price {
        display: block;
        text-align: center;
        font-weight: 700;
        font-size: 3rem;
    }
    .products__promo__title {
        display: block;
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
    }
    .products__pricing {
        background-color: #f2f2f2;
        padding: 15px;
    }
    .products__price {
        display: block;
        font-weight: bold;
        font-size: 1.25rem;
        text-align: center;
    }
    .products__price__info, .products__price__monthly {
        display: block;
        font-size: 1rem;
        text-align: center;
        padding: 2px;
    }
    .products__items {
        background-color: white;
        padding: 15px;
    }
    .products__feature {
        display: block;
        font-size: 1rem;
        &::before {
            content: "-";
            color: $akt-light-blue;
            margin-right: 2px;
            font-weight: bold;
        }
    }
    .products__cta {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        span {
            color: white;
            font-weight: 800;
            font-size: 1rem;
            text-align: center;
            display: block;
            padding: 10px;
        }
        &.akt-dark-teal {
            background-color: $akt-dark-teal;
        }
        &.akt-light-blue {
            background-color: $akt-light-blue;
        }
        &.akt-light-red {
            background-color: $akt-light-red;
        }
    }
    @media screen and (max-width: 599px) {
        .products__title {
            padding: 60px 10px;
        }
        .products__container {
            width: 90%;
        }
    }
    // TESTIMONIALS
    .testimonials {
        background-color: #333;
        color: white;
        padding: 60px;
    }
    .testimonials__title {
        font-weight: 800;
        font-size: 3rem;
        text-align: center;
        margin-bottom: 0px;
    }
    .testimonials__subtitle {
        font-weight: 400;
        font-size: 1.5rem;
        display: block;
        text-align: center;
    }
    .testimonials__slider {
        margin: 60px;
    }
    .testimonials__item {
        img {
            display: block;
            max-width: 100%;
            float: right;
        }
    }
    .testimonials__content {
        background-color: black;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        padding-left: 40px;
        z-index: 1;
    }
    .testimonials__text {
        font-size: 1.25rem;
        font-weight: 400;
    }
    .testimonials__bg {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
    @media screen and (max-width: 959px) {
        .testimonials__item {
            img {
                width: 100%;
            }
        }
        .testimonials__content {
            padding: 40px;
            height: auto;
        }
        .testimonials__slider {
            margin: 60px 10px;
        }
        .testimonials {
            padding: 60px 10px;
        }
    }
    // FAQ 
    .faq {
        padding: 100px;
    }
    .faq__title {
        font-size: 3rem;
        font-weight: 900;
        color: $akt-dark-teal;
        text-align: center;
    }
    .faq__container {
        width: 80%;
        margin: auto;
        display: flex;
        .MuiPaper-elevation1 {
            box-shadow: none;
        }
        .MuiAccordionDetails-root {
            p {
                color: $akt-light-blue;
            }
        }
        .MuiSvgIcon-root {
            color: $akt-light-blue;
        }
        .MuiAccordionSummary-root.Mui-expanded {
            min-height: initial;
        }
        .MuiAccordionSummary-content.Mui-expanded {
            margin: initial;
        }
        p {
            color: black;
            font-weight: 600;
            font-size: 1.25rem;
        }
    }
    .faq__bg {
        max-width: 100%;
    }
    @media screen and (max-width: 599px) {
        .faq {
            padding: 100px 10px;
        }
    }
    // FACT
    .fact {
        background: linear-gradient(rgba($color: black, $alpha: 0.7), rgba($color: black, $alpha: 0.7)), url('../../../assets/akt/fact-bg.jpg') no-repeat center center;
        background-size: cover;
        padding: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
    }
    .fact__title {
        font-weight: 800;
        font-size: 4rem;
        margin-top: 0;
    }
    .fact__subtitle {
        font-weight: 400;
        font-size: 1.5rem;
    }
    .fact__cta {
        background-color: $akt-light-blue;
        border: none;
        padding: 10px 60px;
        border-radius: 25px;
        color: white;
        font-weight: 700;
        font-size: 1.375rem;
    }
    .footer {
        background-color: black;
        padding: 40px;
    }
    .footer__logo__div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .footer__logo {
        max-width: 50%;
    }
    .footer__title {
        font-weight: 700;
        font-size: 1.5rem;
        color: white;
    }
    .footer__feature {
        color: #666;
        font-weight: 500;
        font-size: 1.25rem;
        display: block;
        margin: 10px 0px;
        &::before {
            content: url("../../../assets/akt/akt-mini.png");
            padding: 10px;
            max-width: 50%;
            position: relative;
            top: 4px;
        }
    }
    // FOOTER NAV
    .footernav {
        background-color: #222;
        color: white;
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
        padding: 20px;
        span {
            margin: 20px;
        }
    }
    @media screen and (max-width: 959px) {
        span {
            display: block;
        }
    }
    // LH
    .lh {
        background-color: $akt-light-blue;
        text-align: center;
        color: white;
        font-weight: bold;
    }
}