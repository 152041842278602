.promo {
  font-family: $europa;
  background: $green-linear;
  min-height: 2.25rem;
  display: flex;
  align-items: center;
  &__text {
    margin: 0 auto;
    font-size: 14px;
    color: white;
    padding: 0 1.5rem;
    text-align: center;
    line-height: 1.75rem;
  }
}