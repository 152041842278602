.banner {
  max-width: 1280px;
  margin: 0 auto;
  padding: 75px 120px;
  display: flex;
  @include tablet {
    padding: 75px 25px 100px;
  }
  &__container {
    width: 100%;
    display: flex;
    gap: 40px;
    @include tablet {
      flex-direction: column;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      background-color: $green-background;
      border-radius: 25px;
      padding: 25px;
      gap: 50px;
      @include tablet {
        width: unset;
        align-items: center;
        text-align: center;
      }
      &__section {
        font-family: $europa;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0;
        &--orange {
          color: $orange;
        }
        &--green {
          color: $green;
        }
      }
      &__title {
        font-family: $kansas;
        font-size: 32px;
        font-weight: 600;
        flex-grow: 1;
        margin: 0;
        @include phone {
          font-size: 22px;
        }
      }
      &__green {
        background-color: $green-background;
      }
      &__orange {
        background-color: $orange-background;
      }
    }
  }
}