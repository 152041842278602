.akt {
    .nav {
        display: block;
        position: sticky;
        top: 0;
        z-index: 999;
        font-family: $Montserrat;
    }
    .nav__top {
        background-color: #333;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            color: white;
            font-size: 1.375rem;
            font-weight: 400;
            padding: 5px;
            display: block;
            text-align: center;
        }
    }
    .nav__main {
        background-color: white;
        height: 100px;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nav__nav {
            font-size: 1.25rem;
            font-weight: 500;
            color: black;
            span {
                margin: 0px 25px;
            }
        }
        .nav__cta {
            display: inline;
            .nav__cta1,
            .nav__cta2 {
                border-radius: $gm-radius;
                font-size: 1.25rem;
                font-weight: 700;
                padding: 10px 20px;
            }
            .nav__cta1 {
                background-color: white;
                border: 2px solid $akt-light-blue;
                color: $akt-dark-teal;
            }
            .nav__cta2 {
                margin-left: 20px;
                border: 2px solid $akt-light-blue;
                background-color: $akt-light-blue;
                color: white;
            }
        }
    }
    .nav__logo {
        height: 100%;
        display: flex;
        align-items: center;
        img {
            height: 80%;
        }
    }
    .nav__mobile {
        display: none;
        font-family: $Montserrat;
    }
    $mobile-menu-speed: 0.75s;
    // Mobile
    .nav__mobile {
        position: sticky;
        top: 0;
        z-index: 999;
        padding: 10px;
        background-color: white;
        ul {
            padding-inline-start: 0px;
        }
    }
    .nav__cta {
        .nav__cta1,
        .nav__cta2 {
            border-radius: $gm-radius;
            font-size: 1.25rem;
            font-weight: 800;
            padding: 16px 35px;
        }
        .nav__cta1 {
            background-color: white;
            border: 2px solid $mcf-blue;
            color: $mcf-blue;
        }
        .nav__cta2 {
            margin-left: 20px;
            border: 2px solid $akt-light-blue;
            background-color: $akt-light-blue;
            color: white;
        }
    }
    @media screen and (max-width: 1410px) {
        .menu-wrap {
            position: fixed;
            top: 0;
            right: 0;
            z-index: 1;
        }

        .menu-wrap .toggler {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            cursor: pointer;
            width: 50px;
            height: 50px;
            opacity: 0;
        }

        .menu-wrap .hamburger {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            width: 40px;
            height: 40px;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        /* Hamburger Line */
        .menu-wrap .hamburger > div {
            position: relative;
            flex: none;
            width: 100%;
            height: 2px;
            background: $akt-light-blue;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.4s ease;
        }

        /* Hamburger Lines - Top & Bottom */
        .menu-wrap .hamburger > div::before,
        .menu-wrap .hamburger > div::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: -10px;
            width: 100%;
            height: 2px;
            background: inherit;
        }

        /* Moves Line Down */
        .menu-wrap .hamburger > div::after {
            top: 10px;
        }

        /* Toggler Animation */
        .menu-wrap .toggler:checked + .hamburger > div {
            transform: rotate(135deg);
        }

        /* Turns Lines Into X */
        .menu-wrap .toggler:checked + .hamburger > div:before,
        .menu-wrap .toggler:checked + .hamburger > div:after {
            top: 0;
            transform: rotate(90deg);
        }

        /* Rotate On Hover When Checked */
        .menu-wrap .toggler:checked:hover + .hamburger > div {
            transform: rotate(225deg);
        }

        /* Show Menu */
        .menu-wrap .toggler:checked ~ .hamburger {
            background: none;
            background-color: white;
        }
        .menu-wrap .toggler:checked ~ .menu {
            visibility: visible;
        }

        .menu-wrap .toggler:checked ~ .menu > div {
            transform: scale(1);
            transition-duration: $mobile-menu-speed;
        }

        .menu-wrap .toggler:checked ~ .menu > div > div {
            opacity: 1;
            transition: opacity 0.4s ease 0.4s;
        }

        .menu-wrap .menu {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .menu-wrap .menu > div {
            background-color: rgba($color: $mcf-blue, $alpha: 0.9);
            border-radius: 50%;
            width: 300vw;
            height: 300vw;
            display: flex;
            flex: none;
            align-items: center;
            justify-content: center;
            transform: scale(0);
            transition: all 0.4s ease;
        }

        .menu-wrap .menu > div > div {
            text-align: center;
            max-width: 90vw;
            max-height: 100vh;
            opacity: 0;
            transition: opacity 0.4s ease;
        }

        .menu-wrap .menu > div > div > ul > li {
            list-style: none;
            color: #fff;
            font-size: 1.5rem;
            padding: 1rem;
            margin: 10px 5px;
        }

        .menu-wrap .menu > div > div > ul > li > a {
            color: inherit;
            text-decoration: none;
            transition: color 0.4s ease;
            font-weight: bold;
        }
    }
    @media screen and (max-width: 1410px) {
        .nav {
            display: none;
        }
        .nav__mobile {
            display: block;
            img {
                max-width: 150px;
            }
        }
    }
}
