.gc {
    // HERO
    .hero {
        height: calc(100vh - 152px);
        position: relative;
    }
    .hero__left {
        height: calc(100vh - 152px);
    }
    .hero__left__background {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            position: relative;
            z-index: 2;
            max-height: calc(100vh - 200px);
        }
    }
    .hero__left__light {
        background-color: $gc-beige;
        position: absolute;
        height: 50%;
        width: 100%;
        top: 0;
    }
    .hero__left__dark {
        background-color: $gc-brown;
        position: absolute;
        height: 50%;
        width: 100%;
        bottom: 0;
    }
    .hero__right {
        background: url('../../../assets/gc/hero-bg.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
    .hero__right1 {
        display: block;
        font-size: 5rem;
        font-weight: bold;
        color: white;
        .hero__right__beige {
            color: $gc-beige;
        }
    }
    .hero__right2 {
        display: block;
        font-size: 5rem;
        color: white;
    }
    .hero__right3 {
        display: block;
        font-size: 5rem;
        color: white;
    }
    .hero__cta {
        border-radius: $gm-radius;
        border: none;
        color: white;
        font-size: 1.25rem;
        font-weight: 700;
        padding: 16px 35px;
        background-color: $gc-red;
        transition: 0.5s;
        margin-top: 30px;
        &:hover {
            color: $gc-brown;
            background-color: $gc-beige;
            transition: 0.5s;
        }
    }
    .hero__signature {
        display: block;
        margin-top: 30px;
    }
    @media screen and (max-width: 949px) { 
        .hero__left__background  {
            img {
                max-height: 40vh;
            }
        }
        .hero {
            height: inherit;
        }
        .hero__left {
            height: inherit;
        }
        .hero__signature {
            visibility: hidden;
        }
    }

    // INTRO
    .intro {
        background-color: $gc-beige;
        padding: 80px;
    }
    .intro__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .intro__item {
        margin-top: -130px;
        position: relative;
        width: 346px;
        margin-left: auto;
        margin-right: auto;
        z-index: 4;
        .intro__top {
            text-align: center;
            background-color: $gc-gold;
            border-top-left-radius: $gm-radius;
            border-top-right-radius: $gm-radius;
            img {
                max-width: 100%;
            }
        }
        .intro__bottom {
            background-color: $gc-brown;
            text-align: center;
            border-bottom-left-radius: $gm-radius;
            border-bottom-right-radius: $gm-radius;
            h6 {
                color: white;
                font-weight: 700;
                font-size: 1.375rem;
                margin-block-start: 0;
                margin-block-end: 0;
                padding: 10px;
            }
            span {
                color: white;
                font-weight: 400;
                font-size: 0.8125rem;
                padding: 10px;
                display: block;
                max-width: 80%;
                margin: auto;
            }
        }
    }
    @media screen and (max-width: 1600px) { 
        .intro__item {
            width: 300px;
        }
    }
    @media screen and (max-width: 1400px) { 
        .intro__item {
            width: 250px;
        }
    }
    @media screen and (max-width: 1200px) { 
        .intro__item {
            width: 200px;
        }
    }
    @media screen and (max-width: 1000px) {
        .intro__container {
            flex-direction: column;
        }
        .intro__item {
            width: 80%;
            margin-top: 0px;
            margin-bottom: 20px;
            &:nth-child(1) {
                margin-top: -130px;
            }
        }
    }
    @media screen and (max-width: 599px) { 
        .intro {
            padding: 80px 20px;
        }
    }
    // VIDEO
    .video {
        background: url('../../../assets/gc//video-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        padding: 100px;
        iframe {
            max-width: 100%;
            margin: 20px 10px;
        }
        .video__title1 {
            font-size: 3.5rem;
            color: $gc-brown;
            font-weight: 700;
        }
        .video__title2 {
            font-size: 1.5rem;
            color: $gc-brown;
            font-weight: 700;
        }
        .video__cta {
            border-radius: $gm-radius;
            border: none;
            color: white;
            font-size: 1.75rem;
            font-weight: 700;
            padding: 16px 35px;
            background-color: $gc-gold;
            margin-top: 20px;
            transition: 0.5s;
            &:hover {
                background-color: $gc-brown;
                transition: 0.5s;
            }
        }
        .video__disclaimer {
            display: block;
            font-weight: 400;
            color: $gc-brown;
            margin-top: 10px;
        }
    }
    @media screen and (max-width: 599px) {
        .video {
            padding: 50px 10px;
        }
    }
    // CADEAU
    .cadeau__left {
        background-color: $gc-brown;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 100px;
        padding-top: 75px;
        padding-bottom: 75px;
    }
    .cadeau__left__1 {
        display: block;
        font-size: 2.5rem;
        font-weight: 700;
        color: white;
    }
    .cadeau__left__2 {
        display: block;
        font-size: 2.5rem;
        font-weight: 700;
        color: $gc-gold;
    }
    .cadeau__left__3 {
        display: block;
        font-size: 2.5rem;
        font-weight: 700;
        color: $gc-gold;
    }
    .cadeau__left__4 {
        display: block;
        font-size: 1.5rem;
        font-weight: 700;
        color: $gc-gold;
    }
    .cadeau__left__5 {
        display: block;
        font-size: 1.5rem;
        font-weight: 700;
        color: $gc-gold;
    }
    .cadeau__price__container, .cadeau__price__cents, .cadeau__price__pack {
        color: white;
    }
    .cadeau__price__container, .cadeau__price {
        font-size: 6.5625rem;
        font-weight: 900;
    }
    .cadeau__price__container {
        position: relative;
        display: block;
        margin-bottom: 40px;
    }
    .cadeau__price__cents {
        font-size: 2.5rem;
        font-weight: 900;
    }
    .cadeau__price__pack {
        font-weight: 900;
        font-size: 0.875rem;
        position: relative;
        left: -60px;
        top: -20px;
    }
    .cadeau__price__pack2 {
        font-weight: 900;
        font-size: 0.875rem;
        position: relative;
        left: 10px;
        top: -20px;
        span {
            display: block;
        }
    }
    .cadeau__cta {
        margin-top: 40px;
        button {
            border-radius: $gm-radius;
            border: none;
            color: white;
            font-size: 1.5rem;
            padding: 16px 35px;
            font-weight: 900;
            background-color: $gc-gold;
            text-transform: uppercase;
        }
    }
    .cadeau__right {
        background-color: $gc-gold;
        position: relative;
        height: 100%;
        img {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            max-width: 200%;
            margin: auto;
        }
    }
    @media screen and (max-width: 1550px) { 
        .cadeau__right {
            img {
                max-width: 150%;
            }
        }
    }
    @media screen and (max-width: 949px) {
        .cadeau__left {
            padding: 50px;
        }
        .cadeau__right {
            img {
                position: relative;
                max-width: 100%;
            }
        }
    }
    // USE
    .use {
        max-width: 100%;
        overflow: hidden;
        img {
            height: 100%;
        }
    }
    .use__container {
        background: url('../../../assets/gc/use-bg.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }
    .use__1 {
        font-size: 3.5rem;
        font-weight: 700;
        color: $gc-brown;
        display: block;
        padding: 15px;
    }
    .use__2 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $gc-brown;
        display: block;
        padding: 15px;
    }
    .use__3 {
        font-size: 1.5rem;
        font-weight: 400;
        color: $gc-brown;
        display: block;
        padding: 15px;
    }
    .use__cta {
        margin-top: 40px;
        border-radius: $gm-radius;
        border: none;
        color: white;
        font-size: 1.5rem;
        padding: 16px 35px;
        font-weight: 900;
        background-color: $gc-gold;
        display: block;
        &:hover {
            background-color: $gc-brown;
        }
    }
    .use__icons {
        max-width: 100%;
        padding: 15px;
    }
    @media screen and (max-width: 959px) {
        .use__left {
            position: relative;
            left: -115px;
        }
        .use__container {
            height: inherit;
        }
    }
    @media screen and (max-width: 599px) {
        .use__left {
            left: -225px;
        }
    }
    // SOCIALS
    .socials {
        background: url('../../../assets/gc/socials-bg.jpg') no-repeat center;
        background-size: cover;
        background-color: #333;
    }
    .socials__container {
        width: 80%;
        margin: auto;
    }
    .socials__title {
        color: white;
        font-size: 2.625rem;
        font-weight: 900;
        padding: 50px 0;
        margin: 0;
    }
    .socials__icons {
        float: right;
        img {
            margin: 0px 10px;
        }
        a {
            display: inline;
        }
    }
    @media screen and (max-width: 1279px) { 
        .socials {
            background-size: cover;
        }
        .socials__icons {
            display: block;
            float: unset;
        }
    }
    // FRAMBOISE
    .framboise__left {
        height: 100%;
        img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .framboise__right {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 25px;
        img {
            max-width: 100%;
        }
    }
    .framboise__right__1 {
        color: black;
        font-weight: bold;
        display: block;
        font-size: 2rem;
    }
    .framboise__right__2 {
        color: black;
        font-weight: bold;
        display: block;
        font-size: 2rem;
        margin-bottom: 20px;
    }
    .framboise__right__3 {
        color: black;
        font-weight: 600;
        display: block;
        font-size: 2rem;
        margin-bottom: 20px;
    }
    .framboise__right__4 {
        color: black;
        display: block;
        font-size: 1.5rem;
        margin-bottom: 20px;
    }
    .framboise__cta {
        margin-top: 15px;
        border-radius: $gm-radius;
        border: none;
        color: white;
        font-size: 1.5rem;
        padding: 16px 35px;
        font-weight: 900;
        background-color: $gc-red;
        display: block;
        margin-bottom: 40px;
        transition: 0.5s;
        &:hover {
            background-color: $gc-brown;
            transition: 0.5s;
        }
    }
    // PRODUCTS
    .products {
        max-width: 75%;
        margin: 0 auto 80px auto;
    }
    .promo__desktop {
        img {
            max-width: 100%;
        }
    }
    .promo__mobile {
        display: none;
    }
    .products__title {
        text-align: center;
        font-size: 5rem;
        font-weight: 700;
        color: $gc-brown;
        margin-block-end: 0;
    }
    .products__subtitle {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        color: $gc-brown;
        display: block;
        margin: 30px 0px 60px 0px;
    }
    .products__item {
        margin: 80px 25px;
    }
    .products__top {
        display: flex;
        justify-content: center;
        position: relative;
        img {
            max-height: 238px;
            object-fit: contain;
            margin-bottom: 10px;
            max-width: 100%;
        }
        .framboise__new {
            background-color: $gc-red;
            border-radius: $gm-radius;
            color: white;
            position: absolute;
            top: -30px;
            padding: 4px 8px;
        }
        .products__price__container {
            text-align: center;
        }
        .products__price__reg {
            color: $gc-brown;
            font-weight: 700;
            font-size: 0.875rem;
        }
        .products__price__bubble {
            background-color: $gc-brown;
            color: $gc-beige;
            border-radius: 15px;
            padding: 10px 15px;
        }
        .products__price__text {
            font-weight: 700;
            font-size: 0.875rem;
            display: block;
        }
        .products__price {
            font-weight: 700;
            font-size: 2.25rem;
            white-space: nowrap;
        }
        .products__shipping {
            font-weight: 700;
            font-size: 0.875rem;
            color: $gc-gold;
        }
    }
    .products__bottom {
        text-align: center;
        .products__cta1, .products__cta2 {
            border-radius: $gm-radius;
            border: none;
            font-size: 1.25rem;
            padding: 10px 15px;
            width: 48%;
        }
        .products__cta1 {
            background-color: #FDFAEC;
            color: $gc-brown;
            font-weight: 700;
        }
        .products__cta2 {
            background-color: $gc-gold;
            color: white;
            font-weight: 500;
        }
    }
    @media screen and (max-width: 599px) {
        .products {
            max-width: 90%;
        }
        .products__top {
            img {
                margin: auto;
            }
            flex-direction: column;
        }
    }
    @media screen and (max-width: 320px) {
        .products__title {
            font-size: 3.5rem;
        }
    }
    // SAMPLE
    .sample {
        max-width: 100%;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .sample__container {
        background: url('../../../assets//gc/sample-bg.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }
    .sample__title {
        font-size: 3rem;
        font-weight: 700;
        color: $gc-brown;
        display: block;
        padding: 15px;
    }
    .sample__text {
        font-size: 1.25rem;
        font-weight: 700;
        color: $gc-brown;
        display: block;
        padding: 15px;
    }
    .sample__cta {
        margin-top: 15px;
        border-radius: $gm-radius;
        border: none;
        color: white;
        font-size: 1.5rem;
        padding: 16px 35px;
        font-weight: 900;
        background-color: $gc-gold;
        display: block;
    }
    @media screen and (max-width: 959px) {
        .sample__container {
            height: inherit;
        }
    }
    // VALEURS
    .valeurs {
        background: url('../../../assets/gc/valeurs-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 75px 10px;
        .MuiTabs-root {
            background-color: $gc-gold;
        }
        .MuiTab-textColorInherit {
            color: white;
            font-weight: bold;
        }
        .Mui-selected {
            color: $gc-brown;
        }
        .MuiTabs-indicator {
            background-color: $gc-brown;
            height: 4px;
        }
        .PrivateTabIndicator-colorSecondary-3 {
            background-color: $gc-brown;
        }
        .PrivateTabIndicator-root-1 {
            height: 4px;
        }
    }
    .valeurs__title {
        padding-bottom: 50px;
        text-align: center;
        font-size: 5rem;
        font-weight: 700;
        color: $gc-brown;
        margin-block-end: 0;
        margin-block-start: 0;
    }
    .valeurs__tabs {
        max-width: 70%;
        margin: auto;
    }
    .valeurs__framboise {
        background-color: $gc-red;
    }
    .valeurs__left, .valeurs__right {
        padding: 30px;
    }
    .valeurs__left {
        background-color: white;
        text-align: center;
        width: 100%;
    }
    .valeurs__right {
        background-color: #F8F8F8;
        width: 100%;
        text-align: center;
    }
    .valeurs__gc {
        max-height: 40vh;
    }
    .valeurs__nv {
        max-height: 50vh;
        max-width: 100%;
    }
    @media screen and (max-width: 599px) {
        .valeurs__tabs {
            max-width: 95%;
        }
    }
    // TESTIMONIALS
    .testimonials {
        background-color: $gc-gold;
        color: white;
        padding: 60px;
    }
    .testimonials__item {
        width: 80%;
        background-color: white;
        padding: 30px 20px;
        border-radius: $gm-radius;
        margin: 20px;
        position: relative;
        p {
            color: $gc-brown;
            opacity: 0.8;
        }
        span {
            color: $gc-brown;
            font-weight: bold;
        }
        &.offset {
            margin-left: 40px;
        }
        img {
            max-width: 90%;
        }
        .testimonials__content {
            img {
                position: absolute;
                right: 10px;
                bottom: 10px;
            }
        }
    }
    .testimonials__right {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .testimonials__right__1 {
        display: block;
        font-size: 2rem;
        color: white;
        margin-bottom: 20px;
    }
    .testimonials__right__2 {
        display: block;
        font-size: 3.5rem;
        color: $gc-brown;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .testimonials__right__3 {
        display: block;
        font-size: 2rem;
        color: white;
        margin-bottom: 20px;
    }
    .testimonials__cta {
        border-radius: $gm-radius;
        border: none;
        color: white;
        font-size: 1.5rem;
        padding: 16px 35px;
        font-weight: 900;
        background-color: $gc-brown;
        display: block;
        transition: 0.5s;
        &:hover {
            color: $gc-brown;
            background-color: white;
            transition: 0.5s;
        }
    }

    // STATS
    .stats {
        background-color: $gc-brown;
        padding: 40px;
    }
    .stats__item {
        text-align: center;
        color: white;
    }
    .stats__number {
        color: $gc-beige;
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .stats__description {
        display: block;
        font-weight: 700;
        font-size: 1.25rem;
    }
    // BENEFITS
    .benefits {
        img {
            max-width: 100%;
            display: block;
        }
    }
    .benefits__container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        background-color: #E2E3E8;
    }
    .benefits__1 {
        color: $gc-gold;
        font-weight: 400;
        font-size: 2rem;
        display: block;
        text-align: center;
    }
    .benefits__2 {
        color: $gc-brown;
        font-weight: 700;
        font-size: 5rem;
        display: block;
        text-align: center;
    }
    .benefits__item {
        font-size: 1.75rem;
        font-weight: 600;
        color: $gc-brown;
        position: relative;
        display: block;
        margin: 5px;
        &::before {
            content: url('../../../assets/gc/benefits-icon.png');
            position: absolute;
            left: -30px;
        }
    }
    @media screen and (max-width: 959px) {
        .benefits__container {
            height: inherit;
        }
    }
    // SHIPPING
    .shipping {
        position: relative;
        .shipping__container {
            max-width: 80%;
            margin: auto;
        }
        .shipping__top {
            text-align: right;
            background-color: $gc-beige;
            img {
                right: 175px;
                position: relative;
            }
        }
        .shipping__bottom {
            text-align: right;
            background-color: $gc-brown;
            padding: 15px;
            span {
                color: white;
                font-weight: 500;
                font-size: 2.5rem;
            }
        }
        .shipping__products {
            position: absolute;
            bottom: 0;
            left: 175px;
            max-height: 90%;
        }
    }
    @media screen and (max-width: 1199px) {
        .shipping__products {
            display: none;
        }
        .shipping__top {
            text-align: center !important;
            img {
                right: initial !important;
            }
        }
        .shipping__bottom {
            text-align: center !important;
        }
    }
    // FAQ 
    .faq {
        padding-left: 100px;
    }
    .faq__title {
        font-size: 3.75rem;
        font-weight: 500;
        color: black;
        text-align: center;
    }
    .faq__container {
        margin: auto;
        display: flex;
        .MuiPaper-elevation1 {
            box-shadow: none;
        }
        .MuiIconButton-label {
            background-color: $gc-gold;
        }
        .MuiAccordionDetails-root {
            p {
                color: black;
            }
        }
        .MuiSvgIcon-root {
            color: white;
        }
        .MuiAccordionSummary-root.Mui-expanded {
            min-height: initial;
        }
        .MuiAccordionSummary-content.Mui-expanded {
            margin: initial;
        }
        p {
            color: $gc-brown;
            font-weight: 500;
            font-size: 16px;
        }
    }
    .faq__bg {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: cover;
    }
    @media screen and (max-width: 949px) {
        .faq__bg {
            display: none;
        }
    }
    @media screen and (max-width: 599px) {
        .faq {
            padding: 100px 10px;
        }
    }
    // NEWSLETTER
    .newsletter {
        background: url('../../../assets//gc/newsletter.jpg');
        background-position: center;
        background-size: cover;
        background-position: center;
        padding: 100px;
    }
    .newsletter__title, .newsletter__text {
        color: white;
        display: block;
        text-align: center;
    }
    .newsletter__title {
        font-size: 3.75rem;
        font-weight: 700;
    }
    .newsletter__text {
        font-size: 1.5rem;
        font-weight: 600;
    }
    @media screen and (max-width: 599px) {
        .newsletter {
            padding: 100px 10px;
        }
    }
    // FOOTER
    .footer {
        background-color: $gc-brown;
        padding: 100px;
    }
    .footer__title {
        font-size: 2.25rem;
        color: $gc-beige;
        font-weight: 700;
        text-align: center;
        margin-block-end: 2em;
        margin-block-start: 0;
    }
    .footer__container {
        display: flex;
        max-width: 80%;
        margin: auto;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100%;
            margin: 0px 40px;
        }
    }
    .footer__disclaimer {
        color: white;
        opacity: 0.5;
        display: block;
        text-align: right;
        margin-top: 30px;
    }
    @media screen and (max-width: 1279px) {
        .footer {
            padding: 50px 5px;
        }
        .footer__container {
            flex-direction: column;
        }
    }
    @media screen and (max-width: 599px) {
        .footer__container {
            img {
                margin: 10px 0px;
            }
        }
    }
    // FOOTER NAV
    .footernav {
        background-color: #222;
        color: white;
        font-size: 1.25rem;
        font-weight: 500;
        text-align: center;
        padding: 10px;
        a {
            margin: 20px;
        }
    }
    @media screen and (max-width: 959px) {
        a {
            display: block;
        }
    }
    // LH
    .lh {
        background-color: $gc-gold;
        text-align: center;
        color: white;
        font-weight: bold;
    }
}