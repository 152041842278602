// Color variables
$mcf-green: #71b641;
$mcf-darkgreen: #4e9327;
$mcf-pastel-lightgreen: #bee5a0;
$mcf-pastel-darkgreen-top: #b0e08c;
$mcf-pastel-darkgreen-botttom: #9ed96e;
$mcf-pastel-darkgreen: #b0e08c;

$mcf-blue: #005363;
$mcf-darkblue: #074758;
$mcf-lightblue: #1081a9;
$mcf-pastel-lightblue: #b9d9eb;
$mcf-pastel-darkblue-bottom: #95c6df;
$mcf-pastel-darkblue-top: #abd1e6;
$mcf-pastel-darkblue: #95c6df;
$mcf-blue-low-opacity: rgba(0, 83, 99, 0.1);

$prtl-product-grey: #fdfcfa;

$akt-dark-teal: #035164;
$akt-light-blue: #007faa;
$akt-light-gray: #c3c3c3;
$akt-light-red: #e43a57;
$akt-light-blue-low-opacity: rgba(0, 127, 170, 0.1);

$status-green: #3ce10c;
$status-yellow: #ffc900;
$status-red: #f90e0e;

$mcf-gold: #ffb800;

$shared-light-gray: #dfdedd;

$gm-green: #44602c;
$gm-green-low-opacity: rgba(68, 96, 44, 0.1);

$gc-gold: #b79256;
$gc-brown: #352312;
$gc-beige: #eae1b6;
$gc-red: #a13452;
$gc-brown-low-opacity: rgba(53, 35, 18, 0.1);

$gk-red: #e24553;
$gk-dark-red: #931520;
$gk-light-beige: #fcf3e9;
$gk-dark-beige: #e6c7a8;
$gk-beige-low-opacity: rgba(230, 199, 168, 0.1);

// Various variables
$mcf-radius: 30px;
$gm-radius: 25px;
$gk-radius: 4px;
$mui-grey: rgba(0, 0, 0, 0.54);

// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
$Roboto: "Roboto", sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800;900&display=swap");
$Montserrat: "Montserrat", sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap");
$Raleway: "Raleway", sans-serif;

// // SLICK
// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";

:export {
    primaryMain: $akt-dark-teal;
    secondaryMain: $akt-light-blue;
}

// Colors
$orange: #e7762d;
$green: #57a03e;
$blue: #3e9ba0;
$red: #db3939;
$yellow: #faab39;
$orange-linear: linear-gradient(90deg, #e7762d 0%, #f49206 100%);
$green-linear: linear-gradient(90deg, #52923c 0%, #9fcb26 100%);
$orange-background: #f99f2418;
$green-background: #9fcb2618;
$blue-background: #3e9ba012;
$red-background: #db393912;
$yellow-background: #faab3912;

$grey: #4a4a4a;
$grey90: #4a4a4a90;
$grey20: #4a4a4a20;

// Fonts
$europa: "Roboto", sans-serif;
$kansas: "Montserrat", sans-serif;
