// PRODUCT POPUP
.productpop {
    overflow: hidden;
    .MuiDialog-paperWidthSm {
        min-width: 50vw;
    }
    .MuiDialogContent-root {
        overflow: hidden;
    }
}
.productpop__genericTitle {
    font-size: 2.5rem;
    font-weight: 700;
    color: black;
}
.productpop__title {
    h2 {
        font-weight: 800;
    }
    &.Matcha {
        color: $gm-green;
    }
    &.Choko {
        color: $gc-brown;
    }
    &.Krisp {
        color: $gk-red;
        display: flex;
        justify-content: center;
        text-align: center;
    }
}
.productpop__image {
    max-width: 100%;
    height: auto;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}
.productpop__info__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &.Choko {
        visibility: hidden;
    }
    &.Krisp {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .MuiButton-contained {
        color: white;
        &.Matcha {
            background-color: $gm-green;
        }
        &.Choko {
            background-color: $gc-brown;
        }
        &:hover {
            &.Matcha {
                background-color: rgba($color: $gm-green, $alpha: 0.8);
            }
            &.Choko {
                background-color: rgba($color: $gc-brown, $alpha: 0.8);
            }
        }
    }
    .MuiButton-outlined {
        &.Matcha {
            color: $gm-green;
            outline-color: $gm-green;
        }
        &.Choko {
            color: $gc-brown;
            outline-color: $gc-brown;
        }
    }
}
.productpop__desc {
    font-family: $Roboto;
    &.Krisp {
        font-size: 1.3rem;
    }
}
.productpop__icons {
    width: 75px;
}
.productpop__cta {
    width: 100%;
    display: block !important;
    margin: 10px 0px !important;
    span {
        font-weight: 700;
    }
    &.Krisp {
        background-color: $gk-red;
        color: white;
        font-size: 1rem;
        font-weight: 900;
        padding: 0.5rem;
        border: solid 1px $gk-red;

        &:hover {
            background-color: white;
            border: solid 1px $gk-red;
            color: $gk-red;
        }
    }
}
@media screen and (max-width: 599px) {
    .productpop__image {
        max-height: 150px;
        object-fit: contain;
    }
    .productpop {
        .productpop__images__container {
            padding-bottom: 0px !important;
        }
        .productpop__info__container {
            padding-top: 0px !important;
        }
    }
}