@import "./header";
@import "./slider";
@import "./discover";
@import "./orderOnline";
@import "./questions";
@import "./product";
@import "./making";
@import "./footprint";
@import "./partners";
@import "./customize";
@import "./subscription";
@import "./banner";
@import "./nav";
@import "./promo";
@import "./advantages";
@import "./orderHome";
@import "./how";
@import "./testimonials";
@import "./engagement";
@import "./about";
@import "./newsletter";
@import "./footer";
@import "./header";
@import "./products";
@import "./mission";
@import "./values";
@import "./team";
@import "./orderType";
@import "./howOrder";
@import "./productOrder";
@import "./process";
@import "./progressOrder";
@import "./plan";
@import "./order";

// copyright 
.copyright {
  font-family: $europa;
  font-size: 14px;
}