.advantages {
  max-width: 1280px;
  margin: auto;
  padding:  5rem 25px;
  display: flex;
  justify-content: space-around;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.625rem;
    width: 100%;
  }
  .item__content {
    max-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    &--title {
      font-family: $kansas;
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 0.5rem 0;
    }
    &--text {
      font-family: $europa;
      font-size: 15px;
      color: $grey90;
      margin: 0;
    }
  }
  @include tablet {
    flex-direction: column;
    gap: 5rem;
  }
}