.contact__top {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 152px);
}
.contact__background {
    position: absolute;
    left: 0;
    right: 0;
    top: 152px;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 152px);
    object-fit: cover;
}
.contact__container {
    position: relative;
    background-color: white;
    border-radius: 30px;
    left: 20%;
    padding: 40px;
    max-width: 750px;
    .MuiFormControl-fullWidth {
        margin-top: 20px;
    }
}
.contact__title {
    font-size: 1.5rem;
    font-family: $Roboto;
    font-weight: bold;
    &.faim {
        color: $mcf-blue;
    }
    &.Matcha {
        color: $gm-green;
    }
    &.Aktivation {
        color: $akt-light-blue;
    }
    &.Choko {
        color: $gc-brown;
    }
    &.Krisp {
      color: $gk-dark-beige;
  }
}
.contact__cta {
    margin-top: 25px;
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    font-family: $Roboto;
    font-weight: bold;
    color: white;
    &.faim {
        background-color: $mcf-blue;
    }
    &.Matcha {
        background-color: $gm-green;
    }
    &.Aktivation {
        background-color: $akt-light-blue;
    }
    &.Choko {
        background-color: $gc-brown;
    }
    &.Krisp {
      background-color: $gk-red;
  }
}
#contact__map {
    width: 100%;
    height: 300px;
}
@media screen and (max-width: 1410px) {
    .contact {
        min-height: calc(100vh - 103px);
    }
    .contact__background {
        top: 103px;
        height: calc(100vh - 103px);
    }
    .contact__top {
        min-height: calc(100vh - 103px);
    }
}
@media screen and (max-width: 1200px) {
    .contact__container {
        left: inherit;
        margin: 5px;
    }
    .contact__top {
        justify-content: flex-end;
    }
}